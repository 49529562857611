import React from "react";
import Layout from '../components/layout'

import { Button, Container, Header } from '../components/Elements'

export default () => (
  <Layout>
    <Container type='text'>
      <div className="container">
        <div className="content">
          <Header title='Thank you!'>Thank you!</Header>
          <p>I will review your message, and get back to you as soon as possible.</p>
          <Button to='/'>Back</Button>
        </div>
      </div>
      </Container>
  </Layout>
);